import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { state, style, animate, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('menorMaior', [
      state('menor', style({
        width: '405px',
        height: '520px',
        /* opacity: 0.6; */
        backgroundColor: '#8899aa',
      })),
      state('maior', style({
        width: '433px',
        height: '614px',
        backgroundColor: '#2b4369',
        color: '#ffffff !important',
      })),
      transition('menor => maior', [
        animate('1.0s')
      ])
    ]),
  ]
})

export class HomeComponent implements OnInit {
  // state('menor', style({

  // }))

  _faleConosco: FormGroup;
  constructor(
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this._faleConosco = this._formBuilder.group({
      nomeCompleto: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      telefone: new FormControl(''),
      mensagem: new FormControl(''),
    })
  }

}

<section class="estrutura-home">
  <mat-grid-list cols="4" gutterSize="26px" class="">
    <mat-grid-tile colspan="4" class="" rowHeight="10px">
      <div>
        <h1 class="estrutura-home-header">Estrutura de gerenciamento de riscos e capital</h1>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Buscar">
        </mat-form-field>
        <div class=" metade-row">
          <mat-list>
            <mat-list-item>
              <a mat-button href="/estrutura/#segmento4" class="item-lista-a">
                Estrutura de gerenciamento do segmento S4 até Abril/2019
              </a>

            </mat-list-item>
            <mat-list-item>
              <a mat-button href="/estrutura/#politicaSeguranca" class="item-lista-a">
                Política de segurança cibernética
              </a>

            </mat-list-item>
            <mat-list-item>
              <a mat-button href="/estrutura/#estruturaOuvidoria" class="item-lista-a">
                Estrutura da ouvidoria
              </a>

            </mat-list-item>
          </mat-list>

        </div>
        <div class=" metade-row">
          <mat-list>
            <mat-list-item>
              <a mat-button href="/estrutura/#riscoss5" class="item-lista-a">
                Estrutura de gerenciamento contínuo de riscos S5 á partir de maio/2019
              </a>

            </mat-list-item>
            <mat-list-item>
              <a mat-button href="/estrutura/#politicaPLD" class="item-lista-a">
                Política PLD-CFT
              </a>
            </mat-list-item>
            <mat-list-item>
              <a mat-button href="estrutura/#balancoAnual" class="item-lista-a">
                Balanço anual
              </a>
            </mat-list-item>

          </mat-list>


        </div>

      </div>
    </mat-grid-tile>
  </mat-grid-list>
</section>

<section id="segmento4" class="segmento4">

  <mat-grid-list cols="4" gutterSize="26px" rowHeight="100px" >
    <mat-grid-tile colspan="4">
      <h2 mat-title>
        Estrutura de gerenciamento do segmento S4 até Abril/2019
      </h2>
    </mat-grid-tile>

    <mat-grid-tile colspan="2" rowspan="2" *ngFor="let item of relatoriosSegmento4">
      <app-item-relatorio [modelo]="item"></app-item-relatorio>
    </mat-grid-tile>


  </mat-grid-list>
</section>

<section id="riscoss5" class="riscoss5">

  <mat-grid-list cols="4" gutterSize="26px" rowHeight="100px" class="">

    <mat-grid-tile colspan="4">
      <h2 mat-title>
        Estrutura de gerenciamento contínuo de riscos S5 á partir de maio/2019
      </h2>
    </mat-grid-tile>

    <mat-grid-tile colspan="2" rowspan="2" *ngFor="let item of relatoriosSegmento5">
      <app-item-relatorio [modelo]="item"></app-item-relatorio>
    </mat-grid-tile>

  </mat-grid-list>
</section>

<section id="politicaSeguranca" class="politicaSeguranca">

  <mat-grid-list cols="4" gutterSize="26px" rowHeight="100px" class="">

    <mat-grid-tile colspan="4">
      <h2 mat-title>
        Política de segurança cibernética
      </h2>
    </mat-grid-tile>

    <mat-grid-tile colspan="2" rowspan="2" *ngFor="let item of relatoriosSegurancaCibernetica">
      <app-item-relatorio [modelo]="item"></app-item-relatorio>
    </mat-grid-tile>

  </mat-grid-list>
</section>

<section id="politicaPLD" class="politicaPLD">

  <mat-grid-list cols="4" gutterSize="26px" rowHeight="100px" class="">

    <mat-grid-tile colspan="4">
      <h2 mat-title>
        Política PLD-CFT
      </h2>
    </mat-grid-tile>

    <mat-grid-tile colspan="2" rowspan="2" *ngFor="let item of relatoriosPoliticaPLD">
      <app-item-relatorio [modelo]="item"></app-item-relatorio>
    </mat-grid-tile>

  </mat-grid-list>
</section>

<section id="estruturaOuvidoria" class="estruturaOuvidoria">

  <mat-grid-list cols="4" gutterSize="26px" rowHeight="100px" class="">

    <mat-grid-tile colspan="4">
      <h2 mat-title>
        Estrutura da Ouvidoria
      </h2>
    </mat-grid-tile>

    <mat-grid-tile colspan="2" rowspan="2" *ngFor="let item of relatoriosEstruturaOuvidoria">
      <app-item-relatorio [modelo]="item"></app-item-relatorio>
    </mat-grid-tile>

  </mat-grid-list>
</section>

<section id="balancoAnual" class="balancoAnual">

  <mat-grid-list cols="4" gutterSize="26px" rowHeight="100px" class="">

    <mat-grid-tile colspan="4">
      <h2 mat-title>
        Balanço Anual
      </h2>
    </mat-grid-tile>

    <mat-grid-tile colspan="2" rowspan="2" *ngFor="let item of relatoriosBalancoAnual">
      <app-item-relatorio [modelo]="item"></app-item-relatorio>
    </mat-grid-tile>

  </mat-grid-list>
</section>

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Denuncia } from 'src/app/models/denuncia';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DenunciaService {

  url = `${environment.urlApi}`;

  constructor(
    private service: ApiService<Denuncia>,
  ) { }

  Incluir(dados: Denuncia) {
    return this.service.post(`${this.url}/denuncia/`, dados);
  }

  Alterar(dados: Denuncia) {
    return this.service.put(`${this.url}/denuncia/`, dados);
  }

}

import { ModelBase } from './model-base';
import { Conjunto } from './conjunto';

export class InstituicaoFinanceira extends ModelBase {
  constructor() {
    super();
    this.conjuntos = new Array<Conjunto>();
  }
  cnpj: string;
  nome: string;
  dataUltimaAtualizacao: Date | string;
  contatoArea: string;
  contatoEmail: string;
  conjuntos: Conjunto[];
}

import { ItemRelatorioVm } from './item-relatorio-vm';

export class SegmentoRelatorioVm {
  segmento: string;
  itens: Array<ItemRelatorioVm>;

  constructor(segmento: string, relatorios: Array<ItemRelatorioVm>) {
    this.segmento = segmento;
    this.itens = relatorios;
  }
}

export class UsuarioDenunciaVm {
    cpf: string;
    nome: string;
    telefone: string;
    email: string;
    sobreQuem: string;
    assunto: string;
    relato: string;
    anonimo: boolean;
}

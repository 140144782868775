import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { InstituicaoFinanceira } from 'src/app/models/instituicao-financeira';

@Injectable({
  providedIn: 'root'
})
export class InstituicaoService {

  url = `${environment.urlApi}`;

  constructor(
    private service: ApiService<InstituicaoFinanceira>,
  ) { }

  getFirst() {
    return this.service.get<InstituicaoFinanceira>(`${this.url}/instituicaoFinanceira/primeiro`);
  }

  Incluir(dados: InstituicaoFinanceira) {
    return this.service.post(`${this.url}/instituicaoFinanceira/`, dados);
  }

  Alterar(dados: InstituicaoFinanceira) {
    return this.service.put(`${this.url}/instituicaoFinanceira/`, dados);
  }

}

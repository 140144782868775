import { Recurso } from './recurso';

export class Conjunto {
  constructor(init?: Partial<Conjunto>) {
    Object.assign(this, init);
  }
  referencia: string;
  nome: string;
  urlBase: string;
  urlVisualizacao: string;
  situacao: string;
  recursos: Recurso[];
  contatoArea: string;
  contatoEmail: string;
}

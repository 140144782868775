import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

import { CustomMaterialModule } from './core/custom-material/custom-material.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MenuBarComponent } from './core/menu-bar/menu-bar.component';
import { CadastroInstituicaoComponent } from './cadastros/cadastro-instituicao/cadastro-instituicao.component';
import { MenuBarClienteComponent } from './core/menu-bar-cliente/menu-bar-cliente.component';
import { HomeComponent } from './front-cliente/home/home.component';
import { EstruturaComponent } from './front-cliente/estrutura/estrutura.component';
import { ItemRelatorioComponent } from './front-cliente/estrutura/item-relatorio/item-relatorio.component';
import { DenunciaComponent } from './front-cliente/denuncia/denuncia.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';




const appRoutes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'admin', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'cadastro',
    children: [
      { path: 'instituicao', component: CadastroInstituicaoComponent }
    ]
  },
  { path: 'estrutura', component: EstruturaComponent },
  { path: 'denuncias', component: DenunciaComponent }
]


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CadastroInstituicaoComponent,
    MenuBarComponent,
    MenuBarClienteComponent,
    HomeComponent,
    EstruturaComponent,
    ItemRelatorioComponent,
    DenunciaComponent,
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false }
    ),
    ToastrModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    CustomMaterialModule,
    NgbModule,
  ],
  exports: [
    CustomMaterialModule,
  ],
  providers: [FormBuilder],
  bootstrap: [AppComponent]
})
export class AppModule { }



  <mat-toolbar class="sticky-position">

    <mat-toolbar-row class="container">
      <!-- <button mat-icon-button (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
          </button> -->
      <span>
        <a href="" routerLinkActive="active" mat-button>Direção S.A.</a>
      </span>
      <span class="menu-spacer"></span>
      <div>
        <!-- The following menu items will be hidden on both SM and XS screen sizes -->
        <a href="#quemSomos" routerLinkActive="active" mat-button>Quem Somos</a>
        <a href="#financiamento" mat-button>Financiamento</a>
        <a routerLink="/estrutura" mat-button>Estrutura</a>
        <a href="#fale-conosco" mat-button>Fale Conosco</a>
      </div>
    </mat-toolbar-row>


  </mat-toolbar>


  <mat-sidenav-container  fxFlexFill>
    <mat-sidenav  #sidenav>
      <mat-nav-list>
        <a mat-list-item  routerLink="/cadastro/instituicao" routerLinkActive="active">Instituição</a>
        <a href="#" mat-list-item>Menu Item 2</a>
        <a href="#" mat-list-item>Menu Item 3</a>
        <a href="#" mat-list-item>Menu Item 4</a>
        <a href="#" mat-list-item>Menu Item 5</a>
        <a href="#" mat-list-item>Menu Item 6</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill class="main-container">
        <router-outlet>
  
        </router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  

  <section class="footer container" >
    <mat-grid-list cols="4" gutterSize="50px" rowHeight="340px">
      <mat-grid-tile colspan="4">
        <div class="footer-content">

          <h4>Direção S.A.</h4>
          <p>
            Praça da República, 62 -4º andar conj. 41/43-Centro-Santos-SP-CEP:11013-010
          </p>
          <p>
            <a href="tel:01332024147" class="telefone-footer">013 3202-4147</a>
          </p>
          <p>
            <a href="/denuncias" class="telefone-footer" >Denúncias</a>
          </p>

        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </section>
  


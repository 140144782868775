import { Component, OnInit } from '@angular/core';
import { ItemRelatorioVm } from './item-relatorio/item-relatorio-vm';
import { SegmentoRelatorioVm } from './item-relatorio/segmento-relatorio-vm';

@Component({
  selector: 'app-estrutura',
  templateUrl: './estrutura.component.html',
  styleUrls: ['./estrutura.component.css']
})
export class EstruturaComponent implements OnInit {
  relatorios : Array<ItemRelatorioVm>;
  relatoriosPorSegmento: Array<SegmentoRelatorioVm>;

  constructor() { }

  ngOnInit() {
    this.relatoriosPorSegmento = new Array<SegmentoRelatorioVm>();

    let relatoriosS4 = new Array<ItemRelatorioVm>();
    relatoriosS4.push(new ItemRelatorioVm('Estrutura de gerenciamento de riscos e capital', '../../../assets/arquivos/1 - Estrutura de gerenciamento do segmento S4 até Abril 2019/estrutura_gerenciamento_de_riscos.pdf'));
    relatoriosS4.push(new ItemRelatorioVm('Gerenciamento contínuo e integrado de riscos e gerenciamento contínuo de capital', '../../../assets/arquivos/1 - Estrutura de gerenciamento do segmento S4 até Abril 2019/gerenciamento_continuo_integrado.pdf'));
    relatoriosS4.push(new ItemRelatorioVm('Declaração de apetite por riscos - 2018', '../../../assets/arquivos/1 - Estrutura de gerenciamento do segmento S4 até Abril 2019/declaracao_ras2018.pdf'));
    relatoriosS4.push(new ItemRelatorioVm('Declaração de apetite por riscos - 2019', '../../../assets/arquivos/1 - Estrutura de gerenciamento do segmento S4 até Abril 2019/declaracao_ras2019.pdf'));

    this.relatoriosPorSegmento.push(new SegmentoRelatorioVm('segmento4', relatoriosS4));

    let relatoriosRiscoS5 = new Array<ItemRelatorioVm>();
    relatoriosRiscoS5.push(new ItemRelatorioVm('Politíca GRC - Estrutura Organizacional simplificada', '../../../assets/arquivos/2 - Estrutura de Gerenciamento Contínuo de Riscos S5 a pratir de maio 2019/01-politica-grc-estrutura-organizacional-simplificada.pdf'));
    relatoriosRiscoS5.push(new ItemRelatorioVm('Política GRC - Gerenciamento de risco de crédito', '../../../assets/arquivos/2 - Estrutura de Gerenciamento Contínuo de Riscos S5 a pratir de maio 2019/02-politica-grc-gerenciamento-de-risco-de-credito.pdf'));
    relatoriosRiscoS5.push(new ItemRelatorioVm('Política Grc - Gerenciamento contínuo de riscos', '../../../assets/arquivos/2 - Estrutura de Gerenciamento Contínuo de Riscos S5 a pratir de maio 2019/03-politica-gcr-gerenciamento-continuo-de-riscos.pdf'));
    relatoriosRiscoS5.push(new ItemRelatorioVm('Política RAS - Declaração de apetite de risco', '../../../assets/arquivos/2 - Estrutura de Gerenciamento Contínuo de Riscos S5 a pratir de maio 2019/04-politica-ras-declaracao-de-apetite-de-risco.pdf'));
    relatoriosRiscoS5.push(new ItemRelatorioVm('Política GRLC - Gerenciamento de riscos de liquidez e capital', '../../../assets/arquivos/2 - Estrutura de Gerenciamento Contínuo de Riscos S5 a pratir de maio 2019/05-politica-grlc-gerenciamento-de-risco-de-liquidez-e-capital.pdf'));
    relatoriosRiscoS5.push(new ItemRelatorioVm('Política GRO - Gerenciamento de riscos operacionais', '../../../assets/arquivos/2 - Estrutura de Gerenciamento Contínuo de Riscos S5 a pratir de maio 2019/06-politica-gro-gerenciamento-de-riscos-operacionais.pdf'));
    relatoriosRiscoS5.push(new ItemRelatorioVm('Política PRP - Registro de eventos de perda e ocorrências', '../../../assets/arquivos/2 - Estrutura de Gerenciamento Contínuo de Riscos S5 a pratir de maio 2019/07-politica-prp-registro-de-eventos-de-perda-e-ocorrencias.pdf'));
    relatoriosRiscoS5.push(new ItemRelatorioVm('Política PCS - Seleção e contratação de prestadores de serviços relevantes', '../../../assets/arquivos/2 - Estrutura de Gerenciamento Contínuo de Riscos S5 a pratir de maio 2019/08-politica-pcs-selecao-e-contratacao-de-prestadores-de-servicos-relevan.pdf'));
    relatoriosRiscoS5.push(new ItemRelatorioVm('Política GCN - gerenciamento de continuidade de negocios', '../../../assets/arquivos/2 - Estrutura de Gerenciamento Contínuo de Riscos S5 a pratir de maio 2019/09-politica-gcn-gerenciamento-de-continuidade-de-negocios.pdf'));

    this.relatoriosPorSegmento.push(new SegmentoRelatorioVm('segmento5', relatoriosRiscoS5));

    let relatoriosSeguranca = new Array<ItemRelatorioVm>();
    relatoriosSeguranca.push(new ItemRelatorioVm('Politica de segurança cibernética', '../../../assets/arquivos/3 - Política de Segurança Cibernética/politica-de-seguranca-cibernetica.pdf'));

    this.relatoriosPorSegmento.push(new SegmentoRelatorioVm('segmentoSeguranca', relatoriosSeguranca));

    let relatoriosPolitica = new Array<ItemRelatorioVm>();
    relatoriosPolitica.push(new ItemRelatorioVm('PLD 01 - Política institucional PLDCFT', '../../../assets/arquivos/4 - Política PLD-CFT/pld-01-politica-institucional-pldcft.pdf'));
    relatoriosPolitica.push(new ItemRelatorioVm('PLD 02 - Aspectos Legais e Conceituais PLDCFT', '../../../assets/arquivos/4 - Política PLD-CFT/pld-02-aspectos-legais-e-conceituais-pldcft.pdf'));
    relatoriosPolitica.push(new ItemRelatorioVm('PLD 03 - Estrutura interna PLDCFT', '../../../assets/arquivos/4 - Política PLD-CFT/pld-03-estrutura-interna-pldcft.pdf'));
    relatoriosPolitica.push(new ItemRelatorioVm('PLD 04 - Conheça seu cliente KYC', '../../../assets/arquivos/4 - Política PLD-CFT/pld-04-conheca-seu-cliente-kyc.pdf'));
    relatoriosPolitica.push(new ItemRelatorioVm('PLD 05 - Aceitação, Cadastramento de Cliente PLDCFT', '../../../assets/arquivos/4 - Política PLD-CFT/pld-05-aceitacao-cadastramento-de-clientes-pldcft.pdf'));
    relatoriosPolitica.push(new ItemRelatorioVm('PLD 06 - Conheça seu empregado KYE', '../../../assets/arquivos/4 - Política PLD-CFT/pld-06-conheca-seu-empregado-kye.pdf'));
    relatoriosPolitica.push(new ItemRelatorioVm('PLD 07 - Conheça seu fornecedor parceiro', '../../../assets/arquivos/4 - Política PLD-CFT/pld-07-conheca-seu-fornecedor-parceiro.pdf'));
    relatoriosPolitica.push(new ItemRelatorioVm('PLD 08 - Liquidação das operações', '../../../assets/arquivos/4 - Política PLD-CFT/pld-08-liquidacao-das-operacoes.pdf'));
    relatoriosPolitica.push(new ItemRelatorioVm('PLD 09 - Monitoramento, Seleção, Comunicação Operacional PLDCFT', '../../../assets/arquivos/4 - Política PLD-CFT/pld-09-monitoramento-selecao-comunicacao-operacoes-pldcft.pdf'));
    relatoriosPolitica.push(new ItemRelatorioVm('PLD 10 - Programa de treinamento PLDCFT', '../../../assets/arquivos/4 - Política PLD-CFT/pld-10-programa-de-treinamento-pldcft.pdf'));
    relatoriosPolitica.push(new ItemRelatorioVm('PLD 11 - Avaliação periódica da Auditoria', '../../../assets/arquivos/4 - Política PLD-CFT/pld-11-avaliacao-periodica-da-auditoria.pdf'));
    relatoriosPolitica.push(new ItemRelatorioVm('PLD 12 - Avaliação periódica da diretoria PLDCFT', '../../../assets/arquivos/4 - Política PLD-CFT/pld-12-avaliacao-periodica-da-diretoria-pldcft.pdf'));

    this.relatoriosPorSegmento.push(new SegmentoRelatorioVm('segmentoPoliticaPLD', relatoriosPolitica));

    let _relatoriosEstruturaOuvidoria = new Array<ItemRelatorioVm>();
    _relatoriosEstruturaOuvidoria.push(new ItemRelatorioVm('Estrutura da Ouvidoria', '../../../assets/arquivos/5 - Estrutura da Ouvidoria/estrutura_da_ouvidoria.pdf'));
    _relatoriosEstruturaOuvidoria.push(new ItemRelatorioVm('Relatório Ouvidoria - 1° Semestre', '../../../assets/arquivos/5 - Estrutura da Ouvidoria/relatorio_de_ouvidoria_jun2018.pdf'));
    _relatoriosEstruturaOuvidoria.push(new ItemRelatorioVm('Relatório Ouvidoria - 2° Semestre', '../../../assets/arquivos/5 - Estrutura da Ouvidoria/relatorio_de_ouvidoria_dez2018.pdf'));


    this.relatoriosPorSegmento.push(new SegmentoRelatorioVm('segmentoEstruturaOuvidoria', _relatoriosEstruturaOuvidoria));

    let relasBalancoAnual = new Array<ItemRelatorioVm>();
    relasBalancoAnual.push(new ItemRelatorioVm('Balanço 2018', '../../../assets/arquivos/6 - Balanço Anual/balanco_2018.pdf'));

    this.relatoriosPorSegmento.push(new SegmentoRelatorioVm('segmentoBalancoAnual', relasBalancoAnual));
  }

  get relatoriosSegmento4() : Array<ItemRelatorioVm> {
    const dados = this.relatoriosPorSegmento
      .filter(item => item.segmento == 'segmento4');
    return dados[0].itens;
  }

  get relatoriosSegmento5() : Array<ItemRelatorioVm> {
    const dados = this.relatoriosPorSegmento
      .filter(item => item.segmento == 'segmento5');
    return dados[0].itens;
  }

  get relatoriosSegurancaCibernetica() : Array<ItemRelatorioVm> {
    const dados = this.relatoriosPorSegmento
      .filter(item => item.segmento == 'segmentoSeguranca');
    return dados[0].itens;
  }

  get relatoriosPoliticaPLD() : Array<ItemRelatorioVm> {
    const dados = this.relatoriosPorSegmento
      .filter(item => item.segmento == 'segmentoPoliticaPLD');
    return dados[0].itens;
  }
  get relatoriosEstruturaOuvidoria() : Array<ItemRelatorioVm> {
    const dados = this.relatoriosPorSegmento
      .filter(item => item.segmento == 'segmentoEstruturaOuvidoria');
    return dados[0].itens;
  }

  get relatoriosBalancoAnual() : Array<ItemRelatorioVm> {
    const dados = this.relatoriosPorSegmento
      .filter(item => item.segmento == 'segmentoBalancoAnual');
    return dados[0].itens;
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsuarioDenunciaVm } from './usuario-denuncia-vm';
import { MatStepper } from '@angular/material/stepper';
import { DenunciaService } from 'src/services/denuncia.service';
import { Denuncia } from 'src/app/models/denuncia';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-denuncia',
  templateUrl: './denuncia.component.html',
  styleUrls: ['./denuncia.component.css']
})
export class DenunciaComponent implements OnInit {
  grupoIdentificacao: FormGroup;
  grupoDenuncia: FormGroup = new FormGroup({});
  modeloIdentificacao: UsuarioDenunciaVm = new UsuarioDenunciaVm();
  @ViewChild('stepper') stepper: MatStepper;
  constructor(
    private formBuilder: FormBuilder,
    private service: DenunciaService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {
    this.modeloIdentificacao.anonimo = true;
    this.grupoIdentificacao = this.formBuilder.group({
      cnpj: ['', [Validators.required]],
      cpf: ['', []],
      nome: ['', []],
      telefone: ['', []],
      email: ['', []],
    });

    this.grupoDenuncia = this.formBuilder.group({
      sobreQuem: ['', [Validators.required]],
      assunto: ['', [Validators.required]],
      relato: ['', [Validators.required]],
    });
  }

  enviar() {
    const modeloConvertido = new Denuncia(this.modeloIdentificacao);
    this.service.Incluir(modeloConvertido).then(
      (sucesso) => {
        this.toastr.success('Denúncia cadastrada com sucesso.');
      },
      (erro) => {
        this.toastr.error('Erro');
        console.log(erro);
      }
    )
  }
}

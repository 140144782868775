import { ModelBase } from './model-base';
import { UsuarioDenunciaVm } from '../front-cliente/denuncia/usuario-denuncia-vm';

export class Denuncia extends ModelBase {
    constructor(modeloVM: UsuarioDenunciaVm) {
        super();

        if (modeloVM) {
            this.cpf = modeloVM.cpf;
            this.nome = modeloVM.nome;
            this.telefone = modeloVM.telefone;
            this.email = modeloVM.email;
            this.sobreQuem = modeloVM.sobreQuem;
            this.assunto = modeloVM.assunto;
            this.relato = modeloVM.relato;
            this.anonimo = modeloVM.anonimo;
        }
    }

    cpf: string;
    nome: string;
    telefone: string;
    email: string;
    sobreQuem: string;
    assunto: string;
    relato: string;
    anonimo: boolean;
}

<mat-horizontal-stepper [linear]="false" #stepper class="stepper">
  <mat-step [stepControl]="grupo">
    <form [formGroup]="grupo" (ngSubmit)="salvar()">
      <ng-template matStepLabel>Dados da Instituição</ng-template>
      <mat-form-field appearance="fill" class="width100">
        <mat-label>CNPJ da instituição</mat-label>
        <input matInput formControlName="cnpj" required [(ngModel)]="modelo.cnpj">
        <mat-error>CNPJ informado é inválido</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="width100">
        <mat-label>Nome </mat-label>
        <input matInput formControlName="nome" required [(ngModel)]="modelo.nome">
        <mat-error>Nome é obrigatório</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="width100">
        <mat-label>Data da última atualização' </mat-label>
        <input matInput [matDatepicker]="dataAtualizacaoPicker" formControlName="dataAtualizacao" [(ngModel)]="modelo.dataUltimaAtualizacao">
        <mat-datepicker-toggle matSuffix [for]="dataAtualizacaoPicker"></mat-datepicker-toggle>
        <mat-datepicker #dataAtualizacaoPicker></mat-datepicker>
        <mat-error>Data informada é inválida</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="width100">
        <mat-label>Área de contato </mat-label>
        <input matInput formControlName="contatoArea" [(ngModel)]="modelo.contatoArea">
        <!-- <mat-error>Nome é obrigatório</mat-error> -->
      </mat-form-field>

      <mat-form-field appearance="fill" class="width100">
        <mat-label>E-mail </mat-label>
        <input type="email" matInput formControlName="email" required [(ngModel)]="modelo.contatoEmail">
        <mat-error *ngIf="ctrls.email.errors && ctrls.email.errors.required">E-mail é obrigatório</mat-error>
        <mat-error *ngIf="ctrls.email.errors && ctrls.email.errors.email">E-mail inválido</mat-error>
      </mat-form-field>

      <div class="text-center">
        <button mat-raised-button color="primary">Salvar</button>
      </div>
    </form>
  </mat-step>

  <mat-step>
    <form [formGroup]="grupoConjuntos" (ngSubmit)="salvar()">
      <ng-template matStepLabel>Conjuntos</ng-template>
      <button mat-button type="button" (click)="addRelatorio()">
        <mat-icon>add_circle</mat-icon>
      </button>
      <button mat-button type="button" (click)="validarDados()">
        <mat-icon>add</mat-icon>
      </button>

      <!-- <div *ngFor="let alias of conjuntos.controls; let i=index"> -->
      <!-- The repeated alias template -->

      <mat-accordion *ngFor="let novoGrupoRela as FormGroup of conjuntos.controls; let i=index">
        <mat-expansion-panel style="margin-bottom: 10px !important;">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{novoGrupoRela.controls.nome.value}}
            </mat-panel-title>
            <mat-panel-description>
              Type your name and age
            </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-form-field appearance="fill" class="width100">
            <mat-label>Nome: </mat-label>
            <input type="text" matInput [formControl]="novoGrupoRela.controls.nome">
            <mat-error>Nome é obrigatório</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="width100">
            <mat-label>Url Base: </mat-label>
            <input type="text" matInput [formControl]="novoGrupoRela.controls.urlBase">
            <mat-error>A URL base do relatório é obrigatória</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="width100">
            <mat-label>Url de Visualização: </mat-label>
            <input type="text" matInput [formControl]="novoGrupoRela.controls.urlVisualizacao">
            <mat-error>A URL de visualização do relatório é obrigatória</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="width100">
            <mat-label>Situação: </mat-label>
            <input type="text" matInput [formControl]="novoGrupoRela.controls.situacao">
            <mat-error>A situação é obrigatória</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="width100">
            <mat-label>Área de Contato: </mat-label>
            <input type="text" matInput [formControl]="novoGrupoRela.controls.contatoArea">
          </mat-form-field>

          <mat-form-field appearance="fill" class="width100">
            <mat-label>E-mail da Área: </mat-label>
            <input type="email" matInput [formControl]="novoGrupoRela.controls.contatoEmail">
            <mat-error>E-mail informado é inválido</mat-error>
          </mat-form-field>

          <button mat-button type="button" (click)="addRecurso(i)">
            <mat-icon>add_circle</mat-icon>
          </button>

          <mat-accordion *ngFor="let recurso as FormGroup of conjuntos.value[i].recursos; let subi=index">
            <mat-expansion-panel style="margin-bottom: 10px !important;">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{recurso.controls.nome.value}}
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>

              <button mat-button type="button" (click)="addParametro(i, subi)">
                <mat-icon>add_circle</mat-icon>
              </button>

              <mat-form-field appearance="fill" class="width100">
                <mat-label>Nome: </mat-label>
                <input type="text" matInput [formControl]="recurso.controls.nome">
                <mat-error>Nome é obrigatório</mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill" class="width100">
                <mat-label>Caminho do recurso: </mat-label>
                <input type="text" matInput [formControl]="recurso.controls.caminhoRecurso">
                <mat-error>A URL base do relatório é obrigatória</mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill" class="width100">
                <mat-label>Periodicidade: </mat-label>
                <input type="text" matInput [formControl]="recurso.controls.periodicidade">
                <mat-error>A Periodicidade do relatório é obrigatória</mat-error>
              </mat-form-field>



              <!-- <mat-accordion *ngFor="let parametro as FormGroup of conjuntos.value[i].recursos[subi].parametros; let indiceParametro=index">
                <mat-expansion-panel style="margin-bottom: 10px !important;">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{parametro.controls.nome.value}}
                    </mat-panel-title>
                    <mat-panel-description>

                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <mat-form-field appearance="fill" class="width100">
                    <mat-label>Nome: </mat-label>
                    <input type="text" matInput [formControl]="parametro.controls.nome">
                    <mat-error>Nome é obrigatório</mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="width100">
                    <mat-label>Caminho do recurso: </mat-label>
                    <input type="text" matInput [formControl]="parametro.controls.caminhoRecurso">
                    <mat-error>A URL base do relatório é obrigatória</mat-error>
                  </mat-form-field>

                  <button mat-button type="button" (click)="addParametro(i, subi)">
                    <mat-icon>add_circle</mat-icon>
                  </button>

                </mat-expansion-panel>
              </mat-accordion> -->

            </mat-expansion-panel>
          </mat-accordion>

        </mat-expansion-panel>
      </mat-accordion>

      <div class="text-center button-salvar">
        <button mat-raised-button color="primary">Salvar</button>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>

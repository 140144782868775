<section class="borda-preta" >
  <mat-grid-list cols="4" rowHeight="80vh" class="borda-preta">
    <mat-grid-tile colspan="4" >
      <mat-horizontal-stepper  #stepper>
        <mat-step>
          <ng-template matStepLabel>Resumo</ng-template>
          <p>Prezado,</p>
          <p>

            Este é um canal detstinado a denúncia e pode ser feito por funcionários, colaboradores, clientes, usuários,
            parceiros ou fornecedores. Fique a vontade para realizar uma denúncia caso ocorra alguma situação que fere
            nossa política interna ou Código
            de ética, ou mesmo situações ílicitas de qualquer natureza relacionadas às atividades da Instituição.
          </p>
          <p class=" mx-auto" style="width: 200px">
            <button  matStepperNext mat-raised-button>Proseguir com a denúncia identificada</button>
            <button  (click)="this.stepper.selectedIndex = 2;" mat-raised-button>Proseguir com a denúncia sem identificação</button>
          </p>
        </mat-step>
        <mat-step [stepControl]="grupoIdentificacao" >
          <form [formGroup]="grupoIdentificacao">

            <ng-template matStepLabel>Idendificação</ng-template>

            <mat-form-field appearance="fill" class="width100 ">
              <mat-label>CPF deve ser preenchido ao se identificar</mat-label>
              <input matInput formControlName="cpf" required [(ngModel)]="modeloIdentificacao.cpf">
              <mat-error>CPF deve ser preenchido ao se identificar</mat-error>
            </mat-form-field>
      
            <mat-form-field appearance="fill" class="width100">
              <mat-label>Nome </mat-label>
              <input matInput formControlName="nome" required [(ngModel)]="modeloIdentificacao.nome">
              <mat-error>Nome é obrigatório</mat-error>
            </mat-form-field>
      
            <mat-form-field appearance="fill" class="width100">
              <mat-label>Telefone </mat-label>
              <input matInput formControlName="telefone" [(ngModel)]="modeloIdentificacao.telefone">
            </mat-form-field>

            <mat-form-field appearance="fill" class="width100">
              <mat-label>E-mail </mat-label>
              <input matInput type="email" formControlName="email" [(ngModel)]="modeloIdentificacao.email">
            </mat-form-field>

          </form>
          <div>
            <button mat-button matStepperPrevious>Voltar</button>
            <button  mat-button matStepperNext >Próximo</button>
            <button mat-button (click)="stepper.reset()">Limpar</button>
          </div>
        </mat-step>
        <mat-step [stepControl]="grupoDenuncia" >
          <form  [formGroup]="grupoDenuncia">
            <ng-template matStepLabel>Denúncia</ng-template>
            <mat-form-field appearance="fill" class="width100" >
              <mat-label>Sobre Quem</mat-label>
              <mat-select  matNativeControl required formControlName="sobreQuem" [(ngModel)]="modeloIdentificacao.sobreQuem" >
                <mat-option value="Funcionários">Funcionários</mat-option>
                <mat-option value="Colaboradores">Colaboradores</mat-option>
                <mat-option value="Clientes">Clientes</mat-option>
                <mat-option value="Outros">Outros</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="width100" >
              <mat-label>Assunto</mat-label>
              <mat-select  matNativeControl required formControlName="assunto" [(ngModel)]="modeloIdentificacao.assunto" >
                <mat-option value="Assedio">Assédio</mat-option>
                <mat-option value="Conduta">Conduta</mat-option>
                <mat-option value="Corrupcao">Corrupção</mat-option>
                <mat-option value="ConflitoInteresses">Conflito de Interesses</mat-option>
                <mat-option value="Descriminacao">Descriminação</mat-option>
                <mat-option value="Fraude">Fraude</mat-option>
                <mat-option value="RouboFurto">Roubo/Furto</mat-option>
                <mat-option value="Seguranca">Segurança</mat-option>
                <mat-option value="Outros">Outros</mat-option>
              </mat-select>
            </mat-form-field>
      
            <mat-form-field appearance="fill" class="width100">
              <mat-label>Relato </mat-label>
              <textarea type="text" matInput formControlName="relato" required [(ngModel)]="modeloIdentificacao.relato" rows="20"></textarea>
            </mat-form-field>
          </form>
          <div>
            <button mat-button matStepperPrevious>Voltar</button>
            <button  mat-button matStepperNext color="primary" (click)="enviar()" >Enviar</button>
            <button mat-button (click)="stepper.reset()">Limpar</button>
          </div>
          
        </mat-step>
      </mat-horizontal-stepper>
    </mat-grid-tile>
  </mat-grid-list>
</section>
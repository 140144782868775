import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/services/login.service';
import { Login } from '../models/login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hide: boolean;
  email = new FormControl('', [Validators.required, Validators.email]);
  usuario: Login = new Login();
  
  constructor(
    private loginService: LoginService,
  ) {
   }

  ngOnInit(): void {
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  logar() {
    this.loginService.get(this.usuario)
      .then(sucess => {
        const teste = sucess;
        })
        .catch(erro => {
          const teste = erro;
        });
  }

}

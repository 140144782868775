import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { InstituicaoService } from 'src/services/instituicao.service';
import { InstituicaoFinanceira } from 'src/app/models/instituicao-financeira';
import { Conjunto } from 'src/app/models/conjunto';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cadastro-instituicao',
  templateUrl: './cadastro-instituicao.component.html',
  styleUrls: ['./cadastro-instituicao.component.css']
})
export class CadastroInstituicaoComponent implements OnInit {

  // cnpjControl: FormControl = new FormControl(null, [Validators.required]);
  grupo: FormGroup;
  grupoConjuntos: FormGroup;
  modelo: InstituicaoFinanceira = new InstituicaoFinanceira();
  get ctrls() {
    return this.grupo.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private instituicaoService: InstituicaoService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.grupo = this.formBuilder.group({
      cnpj: ['', [Validators.required]],
      nome: ['', [Validators.required]],
      dataAtualizacao: ['', [Validators.required]],
      contatoArea: ['', null],
      email: ['', [Validators.required, Validators.email]],
    });

    this.grupoConjuntos = this.formBuilder.group({
      conjuntos: this.formBuilder.array([
      ])
    });

    this.pesquisarInstituicao();


  }

  get conjuntos() {
    return this.grupoConjuntos.get('conjuntos') as FormArray;
  }

  get recursos() {
    return this.grupoConjuntos.get('conjuntos') as FormArray;
  }

  addRelatorio(conjunto?: Conjunto) {

    let conjuntoAux = new Conjunto();
    if (conjunto) {
      conjuntoAux = conjunto;
    } else {
      conjuntoAux.nome = 'Novo Relatório';
    }

    const formAux = this.formBuilder.group({
      nome: this.formBuilder.control(conjuntoAux.nome, [Validators.required]),
      urlBase: this.formBuilder.control(conjuntoAux.urlBase, [Validators.required]),
      urlVisualizacao: this.formBuilder.control(conjuntoAux.urlVisualizacao),
      situacao: this.formBuilder.control(conjuntoAux.situacao, [Validators.required]),
      contatoArea: this.formBuilder.control(conjuntoAux.contatoArea, [Validators.required]),
      contatoEmail: this.formBuilder.control(conjuntoAux.contatoEmail, [Validators.email]),
      recursos: this.formBuilder.array([
        // {parametros: this.formBuilder.group({}),}
      ]),
    });

    this.conjuntos.push(formAux);
  }

  addRecurso(conjuntoIndice: number) {
    const formAux = this.formBuilder.group({
      nome: this.formBuilder.control('Novo Recurso', [Validators.required]),
      caminhoRecurso: this.formBuilder.control('', [Validators.required]),
      periodicidade: this.formBuilder.control('', [Validators.required]),
      parametros: this.formBuilder.array([]),
    });

    // let teste = this.conjuntos.value[conjuntoIndice];
    // let teste2 = this.conjuntos.value[conjuntoIndice].recursos.controls;
    this.conjuntos.value[conjuntoIndice].recursos.push(formAux);

  }

  addParametro(conjuntoIndice: number, recursoIndice: number) {

    const formAux = this.formBuilder.group({
      nome: this.formBuilder.control('Novo Parâmetro', [Validators.required]),
      valores: this.formBuilder.array([]),
    });

    // let teste = this.conjuntos.value[conjuntoIndice].recursos[recursoIndice].parametros;
    this.conjuntos.value[conjuntoIndice].recursos[recursoIndice].parametros.push(formAux);

  }

  tratarValorControl(teste: any) {
    let teste2 = teste;
  }

  salvar() {
    debugger;
    if (this.grupo.invalid) {
      return;
    } else {

      const teste = this.conjuntos.controls;

      if (this.modelo && !this.modelo.id) {
        this.Incluir();
      }
      else {
        this.Alterar();
      }
    }
  }

  validarDados() {
    debugger;
    let teste = this.conjuntos.controls[0] as FormGroup;

  }

  pesquisarInstituicao() {
    this.instituicaoService.getFirst()
      .then((sucesso) => {
        debugger;
        const teste = sucesso;
        if (sucesso) {
          this.modelo = sucesso;
          if (this.modelo.conjuntos) {
            this.modelo.conjuntos.forEach(item => {
              this.addRelatorio(item);
            });
          }
          else {
            this.modelo.conjuntos = new Array<Conjunto>();
          }
        }
      })
      .catch((erro) => {
        console.log("Deu erro");
        this.toastr.error(erro);
      })
  }

  Incluir() {
    debugger;
    this.instituicaoService.Incluir(this.modelo)
      .then((sucesso) => {
        debugger;
        const teste = sucesso;
        this.modelo = sucesso;
        this.modelo.conjuntos.forEach(item => {
          this.addRelatorio(item);
        });
      })
      .catch((erro) => {
        console.log("Deu erro");
        this.toastr.error(erro);
      })
  }

  Alterar() {
    debugger;
    this.modelo.conjuntos = new Array<Conjunto>();
    this.conjuntos.value.forEach(item => {
      this.modelo.conjuntos.push(new Conjunto(item));
    });
    this.instituicaoService.Alterar(this.modelo)
      .then((sucesso) => {
        debugger;
        const teste = sucesso;
        this.modelo = sucesso;
        this.modelo.conjuntos.forEach(item => {
          this.addRelatorio(item);
        });
      })
      .catch((erro) => {
        console.log("Deu erro");
        this.toastr.error(erro);
      })
  }

}

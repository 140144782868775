import { Component, OnInit, Input } from '@angular/core';
import { ItemRelatorioVm } from './item-relatorio-vm';

@Component({
  selector: 'app-item-relatorio',
  templateUrl: './item-relatorio.component.html',
  styleUrls: ['./item-relatorio.component.css']
})
export class ItemRelatorioComponent implements OnInit {

  @Input("modelo") modelo: ItemRelatorioVm;
  constructor() { }

  ngOnInit() {
    // this.modelo = new ItemRelatorioVm('', '');
  }

}

<div class="container">

  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span>Dashboard</span>
      <span class="menu-spacer"></span>
      <div>
        <!-- The following menu items will be hidden on both SM and XS screen sizes -->
        <a routerLink="/cadastro/instituicao" routerLinkActive="active" mat-button>Instituição</a>
        <a href="#" mat-button>Menu Item 2</a>
        <a href="#" mat-button>Menu Item 3</a>
        <a href="#" mat-button>Menu Item 4</a>
        <a href="#" mat-button>Menu Item 5</a>
        <a href="#" mat-button>Menu Item 6</a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container" fxFlexFill>
    <mat-sidenav #sidenav >
      <mat-nav-list>
        
        <a mat-list-item routerLink="/cadastro/instituicao" routerLinkActive="active" >Instituição</a>
        <a href="#" mat-list-item>Menu Item 2</a>
        <a href="#" mat-list-item>Menu Item 3</a>
        <a href="#" mat-list-item>Menu Item 4</a>
        <a href="#" mat-list-item>Menu Item 5</a>
        <a href="#" mat-list-item>Menu Item 6</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>

</div>
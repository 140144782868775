import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Login } from 'src/app/models/login';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  url = `${environment.urlApi}/users`;

  constructor(
    private service: ApiService<Login>,
  ) {

  }

  get(usuario: Login): Promise<Login> {
    return this.service.post(`${this.url}/authenticate`, usuario);
  }
}

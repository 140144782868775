<div class="container justify-content-center">
  <section class="inicial ">
    <mat-grid-list cols="4" gutterSize="60px" >
      <mat-grid-tile colspan="2">
  
      </mat-grid-tile>
      <mat-grid-tile colspan="2" class="">
          <div class="row-condicoes ">
            <h1 class="inicial-cabecalho ">
              As melhores condições pra você!
            </h1>
            <p class="font-pequena">
              Somos uma empresa especializada em crédito, financiamento e investimento. Assine nossa newsletters e venha nos
              conhecer!
            </p>
            <p class="font-pequena newsletter">
              <mat-form-field appearance="fill" class="input-newsletter">
                <input matInput placeholder="Escreva seu e-mail">
                <button matSuffix aria-label="Clear" mat-raised-button color="primary">
                  Assinar
                </button>
              </mat-form-field>
            </p>
          </div>
      </mat-grid-tile>
  
      <mat-grid-tile colspan="2" class="">
        <div class=" roda-pe-inicial-consultores">
          <div class=" bloco-img">
            <img src="../../../assets/imagens/consultores-ilustra.png">
  
          </div>
          <div class=" bloco-texto">
            <h3>Consultores</h3>
            <p>Um time especializado para atender todos os clientes de maneira única e especial.</p>
          </div>
        </div>
      </mat-grid-tile>
      
      <mat-grid-tile colspan="2" class="">
        <div class=" roda-pe-inicial-consultores">
          <div class="  bloco-img">
            <img src="../../../assets/imagens/ilustra-solidez.png">
          </div>
          <div class="bloco-texto ">
            <h3>Solidez</h3>
            <p>A mais de 50 anos no mercado, com mais de 5.7 milhões de solicitações de crédito.</p>
          </div>
        </div>
      </mat-grid-tile>
  
    </mat-grid-list>
    
  </section>
  <section id="quemSomos" class="quemSomos">
    <mat-grid-list cols="4" gutterSize="60px" rowHeight="760px">
      <mat-grid-tile colspan="2">
        <div class="quemSomos-imagem">
          <img src="../../../assets/imagens/quemsomos-imagem.png">
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <div class="quemSomos-texto">
          <h1>Quem Somos</h1>
          <p>
            A Direção S.A. Crédito, Financiamento e Investimento, presente há 50 anos no mercado, surgiu com o propósito de
            oferecer soluções de crédito para pessoas físicas e jurídicas. Seu principal diferencial é o estreito
            relacionamento que mantém com os clientes.
          </p>
          <p>
  
            Tendo em vista a missão de fazer financiamentos para pessoas físicas e jurídicas, com rapidez e qualidade, através
            de firmes parcerias e a visão de crescer sempre com solidez, mantendo o valor da honestidade, integridade e
            lealdade a cada cliente. Assim, podemos propiciar aos nossos clientes um atendimento mais eficiente e taxas ainda
            melhores.
          </p>
          <p>
            Para adquirir melhor conhecimento sobre o nosso trabalho ou começar uma parceria de sucesso, fale conosco.
          </p>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </section>

  <section id="financiamento" class="quemSomos financiamento-comunicado" >
    <mat-grid-list  cols="4" gutterSize="0px" rowHeight="600px" >
      <mat-grid-tile colspan="2" >
        <img src="../../../assets/imagens/home-direção-1.png" >
      </mat-grid-tile>
      <mat-grid-tile  colspan="2" >
        <div >
          <h1>Financiamento e Empréstimo</h1>
          <p>A Direção S. A. está preparada para atender a Micro, Pequenas e Médias Empresas. Procura sempre oferecer as
            taxas
            mais competitivas e os melhores serviços, proporcionando um atendimento personalizado.</p>
            
            <p>A equipe da Direção S. A. está sempre à disposição e inserida no dia a dia do cliente, pronta para solucionar
              as
              suas necessidades, tudo isso para trazer mais praticidade para o seu negócio.</p>
            </div>
            
          </mat-grid-tile>
          
    </mat-grid-list>
  </section>
  <section id="comunicado" class="quemSomos financiamento-comunicado">
    <mat-grid-list  cols="4" gutterSize="0px" rowHeight="600px">
      <mat-grid-tile colspan="2" >
        <div >
          <h1>Comunicado Importante</h1>
          <p>
            A Direção S.A. Crédito, Financiamento e Investimento, presente há 50 anos no mercado, surgiu com o propósito de
            oferecer soluções de crédito para pessoas físicas e jurídicas. Seu principal diferencial é o estreito
            relacionamento que mantém com os clientes.
          </p>
          <p>
  
            Tendo em vista a missão de fazer financiamentos para pessoas físicas e jurídicas, com rapidez e qualidade,
            através
            de firmes parcerias e a visão de crescer sempre com solidez, mantendo o valor da honestidade, integridade e
            lealdade a cada cliente. Assim, podemos propiciar aos nossos clientes um atendimento mais eficiente e taxas
            ainda
            melhores.
          </p>
          <p>
            Para adquirir melhor conhecimento sobre o nosso trabalho ou começar uma parceria de sucesso, fale conosco.
          </p>
  
        </div>
      </mat-grid-tile>
      <mat-grid-tile  colspan="2" >
        
        <img src="../../../assets/imagens/home-direção-2.png" >
      </mat-grid-tile>
    </mat-grid-list>
  </section>
  <section id="planos" class="planos">
  
    <mat-grid-list cols="3" gutterSize="100px" rowHeight="80px">
      <mat-grid-tile colspan="3">
        <h1 class="planos-titulo">Conheça nossos planos</h1>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="3" gutterSize="25px" rowHeight="70vh">
  
      <mat-grid-tile>
        <mat-card class="planos-item ">
          <mat-card-content>
            <div class="img-container">
              <img src="../../../assets/imagens/ilustra1.png">
            </div>
            <div class="container-cabecalho ">
              Financiamento de Capital de giro
            </div>
            <div class="container-corpo ">
              Contate um dos nossos consultores e conheça suas vantagens
            </div>
            <div class="container-rodape">
              <button class="contatar " mat-raised-button color="primary">
                Contatar
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
  
      <mat-grid-tile>
        <mat-card class="planos-item ">
          <mat-card-content>
            <div class="img-container">
              <img src="../../../assets/imagens/escudo-ilustra.png">
            </div>
            <div class="container-cabecalho ">
              Empréstimos com Garantia
            </div>
            <div class="container-corpo ">
              Contate um dos nossos consultores e conheça suas vantagens
            </div>
            <div class="container-rodape">
              <button class="contatar " mat-raised-button color="primary">
                Contatar
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
  
      <mat-grid-tile>
        <mat-card class="planos-item ">
          <mat-card-content>
            <div class="img-container">
              <img src="../../../assets/imagens/carro-ilustra.png">
            </div>
            <div class="container-cabecalho ">
              Financiamentos de Veículos
            </div>
            <div class="container-corpo ">
              Contate um dos nossos consultores e conheça suas vantagens
            </div>
            <div class="container-rodape">
              <button class="contatar " mat-raised-button color="primary">
                Contatar
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
  
    </mat-grid-list>
  
  </section>
  <section id="fale-conosco" class="fale-conosco ">
    <mat-grid-list cols="3" rowHeight="80vh" gutterSize="50px">
      <mat-grid-tile class="fale-conosco-imagem " colspan="2">
  
      </mat-grid-tile>
      <mat-grid-tile class="fale-conosco-formulario ">
        <mat-card>
          <mat-card-header>
            Fale Conosco
          </mat-card-header>
          <mat-card-content>
            <form [formGroup]="_faleConosco">
              <mat-form-field appearance="fill">
                <input matInput formControlName="nomeCompleto" type="text" placeholder="Nome Completo" required>
                <mat-error>Campo obrigatório</mat-error>
              </mat-form-field>
  
              <mat-form-field appearance="fill">
                <input matInput formControlName="email" type="email" placeholder="E-mail" required>
                <mat-error>Campo obrigatório</mat-error>
              </mat-form-field>
  
              <mat-form-field appearance="fill">
                <input matInput formControlName="telefone" type="text" placeholder="Telefone">
              </mat-form-field>
  
              <mat-form-field appearance="fill">
                <textarea matInput formControlName="mensagem" type="text" placeholder="Mensagem" class="mensagem">
  
                </textarea>
                <mat-error>Campo obrigatório</mat-error>
              </mat-form-field>
              <mat-card-actions>
                <button mat-raised-button color="primary">
                  Realizar Cadastro
                </button>
              </mat-card-actions>
            </form>
          </mat-card-content>
        </mat-card>
  
      </mat-grid-tile>
    </mat-grid-list>
  
  </section>
  
  <section class="atendimento ">
    <mat-grid-list cols="4" rowHeight="75px" gutterSize="50px">
      <mat-grid-tile colspan="4">
        <h1>Atendimento</h1>
      </mat-grid-tile>
    </mat-grid-list>
  
    <mat-grid-list cols="4" rowHeight="100px" gutterSize="50px">
      <mat-grid-tile colspan="4">
        <h2 class="nossa-equipe">Fale com a nossa equipe especializada para maiores informações sobre os produtos
          oferecidos pela Direção S. A e para simulação de crédito.</h2>
      </mat-grid-tile>
    </mat-grid-list>
  
    <mat-grid-list cols="4" rowHeight="75px" gutterSize="50px">
      <mat-grid-tile colspan="4">
        <mat-icon class="phone-icon">phone</mat-icon> <a href="tel:01332024147" class="telefone">013 3202-4147</a>
      </mat-grid-tile>
    </mat-grid-list>
  
    <mat-grid-list cols="4" rowHeight="500px" gutterSize="50px">
      <mat-grid-tile colspan="2" class="">
        <img src="../../../assets/imagens/ilustra-ima.png" />
      </mat-grid-tile>
  
      <mat-grid-tile colspan="2" class="ouvidoria ">
        <div class=" ouvidoria-linha">
          <h2>Ouvidoria</h2>
          <table class=" ouvidoria-tabela">
            <tbody>
              <tr>
                <td class="ouvidoria-tabela-label">E-mail:</td>
                <td>
                  <a href="mailto:ouvidoria@direcaosa.com.br">ouvidoria@direcaosa.com.br</a>
                </td>
              </tr>
              <tr>
                <td class="ouvidoria-tabela-label">Tel.: </td>
                <td>
  
                  <a href="tel:0800-774-4147">0800-774-4147</a>
                </td>
              </tr>
              <tr>
                <td class="ouvidoria-tabela-label">Direção:</td>
                <td>
  
                  <a href="mailto:direcao@direcaosa.com.br">direcao@direcaosa.com.br</a>
                </td>
              </tr>
              <tr>
                <td class="ouvidoria-tabela-label">Contrato:</td>
                <td>
  
                  <a href="mailto:direcao@direcaosa.com.br">contrato@direcaosa.com.br</a>
                </td>
              </tr>
              <tr>
                <td class="ouvidoria-tabela-label">Cadastro:</td>
                <td>
  
                  <a href="mailto:cadastro@direcaosa.com.br">cadastro@direcaosa.com.br</a>
                </td>
              </tr>
              <tr>
                <td class="ouvidoria-tabela-label">Cobrança:</td>
                <td>
  
                  <a href="mailto:cadastro@direcaosa.com.br">cobranca@direcaosa.com.br</a>
                </td>
              </tr>
              <tr>
                <td class="ouvidoria-tabela-label">Departamento Pessoal:</td>
                <td>
  
                  <a href="mailto:rh@direcaosa.com.br">rh@direcaosa.com.br</a>
                </td>
              </tr>
            </tbody>
          </table>
  
        </div>
  
      </mat-grid-tile>
  
    </mat-grid-list>
  </section>
  
  
</div>


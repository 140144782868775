<mat-card-content class="login-container ">
  <div class="example-container ">
    <mat-form-field appearance="fill">
      <mat-label>Usuário:</mat-label>
      <input matInput required [(ngModel)]="usuario.usuario">
      <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>


    <mat-form-field appearance="fill">
      <mat-label>Senha</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="usuario.senha">
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>

    <div class="btn-login">
      <button type="submit" placeholder="Logar" mat-raised-button color="primary" (click)="logar()"> Logar </button>
    </div>
  </div>

</mat-card-content>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-bar-cliente',
  templateUrl: './menu-bar-cliente.component.html',
  styleUrls: ['./menu-bar-cliente.component.css']
})
export class MenuBarClienteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
